<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="tablesix">
        <div class="beijing" v-if="nianfen_xianshi_yincang">
            <div class="box1">
                <p class="tit_box">销售目标进度</p>
                <p class="con">请选择创建销售目标进度的年份</p>
                <div class="con_box">
                    选择年份&nbsp;&nbsp;&nbsp;&nbsp;
                    <el-date-picker
                        v-model="nian_sj"
                        type="year"
                        format='yyyy年'
                        placeholder="选择年">
                    </el-date-picker>
                </div>
                <div class="foot">
                    <span @click="xuanze_nianfen_queding">确定</span>
                    <span @click="xuanze_nianfen_quxiao">取消</span>
                </div>
            </div>
        </div>
        <div class="box">
            <p class="box_tit">{{time}}&nbsp;&nbsp;销售目标进度</p>
            <!-- <i class="lishi" @click="dianji_lishi_chaxun">历史查询<i></i></p> -->
            <div class="table_tit">
                <div class="table_tit_left">
                    <p class="xinjian" @click="nianfen_xianshi_yincang=true">新建</p>
                    <div class="table_tit_left_nianfen">
                        年份
                        <el-select v-model="time" @change='xuanze_nianfen_biao'>
                            <el-option
                                v-for="(i,index) in year_list"
                                :key="index"
                                :label="i"
                                :value="i">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="xuanze">
                    <p @click="qiehuan_zong_mingxi(index)" v-for="(i,index) in huizong_mingxi_list" :key='index'><img :src="huizong_mingxi==index?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')" alt="">{{i}}</p>
                </div>
                <div class="table_tit_right">
                    <p class="xuanze_nianfen">
                        筛选
                        <el-select v-model="shaixuan" @change='shaixuan_biao'>
                            <el-option
                                v-for="(i,index) in shaixuan_list"
                                :key="index"
                                :label="i"
                                :value="i">
                            </el-option>
                        </el-select>
                    </p>
                    单位 : 元
                    <!-- <el-switch
                        class="demo"
                        v-model='danwei'
                        @change="danwei_xuanze"
                        active-color="#ff4949"
                        inactive-color="#ff4949"
                        active-text="万元"
                        inactive-text="元">
                    </el-switch> -->
                </div>
            </div>
            <div class="table">
                <div class="table_right">
                    <p @click="dianji_guanbi">关闭</p>
                    <p @click="dianji_baocun" :style="shifou_baocun?'background:#9a86db;border:0.01rem solid #9a86db;':'background:#B8C1CE;border:0.01rem solid #B8C1CE;'">保存</p>   <!--shifou_baocun  true不能点false能点-->
                    <!-- :style="shifou_baocun?'background:#B8C1CE;border:0.01rem solid #B8C1CE;':''" -->
                    <p @click="dianji_chutu" :style="chutu?'background:#9a86db;border:0.01rem solid #9a86db;color:#fff;':'background:#B8C1CE;border:0.01rem solid #B8C1CE;color:#fff;'">出图</p>
                    <!-- :style="zhanshi==1?'background:#9a86db;color:#fff;border:0.01rem solid #9a86db;margin-right:0;':'margin-right:0;'"  -->
                </div>
            </div>
            <div class="table_con">
                <el-table :data="neirong_list"
                    border
                    max-height="600"
                    :cell-style="cellStyle_tiao"
                    :header-cell-style="headerCellStyle"
                    >
                    <el-table-column v-for="(i, ind) in biaotou"
                        :key="`col_${ind}`"
                        :prop="i.con"
                        width='180'
                        :fixed="i.con=='dept_name'||i.con=='quannian'"
                        :label="i.name">
                        <template slot-scope="props">
                            <div class="baifenhao">
                                {{props.row.dept_name!='计划进度比例'?props.row[biaotou[ind].con]:''}}
                                {{props.row.dept_name=='计划进度比例'&&biaotou[ind].con=='dept_name'?props.row[biaotou[ind].con]:''}}
                                {{props.row.dept_name=='计划进度比例'&&biaotou[ind].con=='quannian'?props.row[biaotou[ind].con]:''}}
                                <input type="number" v-if="props.row.dept_name=='计划进度比例'&&biaotou[ind].con!='quannian'&&biaotou[ind].con!='dept_name'&&chaxun_or_xiugai" @keyup="shuzhi_bilv(props.row,i.con)" v-model="props.row[i.con]">
                                {{props.row.dept_name=='计划进度比例'&&biaotou[ind].con!='quannian'&&biaotou[ind].con!='dept_name'&&!chaxun_or_xiugai?props.row[biaotou[ind].con]:''}}
                                <span class="baifen_hao" v-if="biaotou[ind].con!='dept_name'&&props.row.dept_name=='计划进度比例'">%</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table_con" style="margin-top:0.3rem">
                <el-table :data="neirong_list2"
                    border
                    max-height="600"
                    :cell-style="cellStyle_tiao"
                    :header-cell-style="headerCellStyle"
                    >
                    <el-table-column v-for="(i, ind) in biaotou2"
                        :key="`col_${ind}`"
                        :prop="biaotou2[ind].con"
                        width='180'
                        :fixed="i.con=='dept_name'||i.con=='quannian'"
                        :label="i.name">
                        <template slot-scope="props">
                            <div class="baifenhao">
                                {{props.row.dept_name!='实际/计划进度比例'?props.row[biaotou2[ind].con]:''}}
                                {{props.row.dept_name=='实际/计划进度比例'&&biaotou2[ind].con=='dept_name'?props.row[biaotou2[ind].con]:''}}
                                {{props.row.dept_name=='实际/计划进度比例'&&biaotou2[ind].con=='quannian'?props.row[biaotou2[ind].con]:''}}
                                <!-- <input type="number" v-if="props.row.dept_name=='计划进度比例'&&biaotou2[ind].con!='quannian'&&biaotou2[ind].con!='dept_name'&&chaxun_or_xiugai" v-model="props.row[biaotou2[ind].con]"> -->
                                {{props.row.dept_name=='实际/计划进度比例'&&biaotou2[ind].con!='quannian'&&biaotou2[ind].con!='dept_name'?props.row[biaotou2[ind].con]:''}}
                                <span class="baifen_hao" v-if="biaotou2[ind].con!='dept_name'&&props.row.dept_name=='实际/计划进度比例'">%</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <tuliu ref="mubiao_tu6"></tuliu>
    </div>
</template>

<script>
import tuliu from '../Figures/Figuresix'
import { query_as_aim_plan_spurt, query_as_aim_plan, insert_as_aim_plan, supply_as_aim_plan_data, query_as_year_list } from '../../api/api.js'
export default {
  name: 'tablesix',
  data () {
    return {
      time: '',
      nian_sj: '',
      lo: '',
      year_list: [],
      shifou_baocun: false,
      nianfen_xianshi_yincang: '',
      neirong_list: [],
      neirong_list2: [],
      biaotou: [
        {
          name: '日期（计划法）',
          con: 'dept_name'
        },
        {
          name: '全年',
          con: 'quannian'
        },
        {
          name: '1月',
          con: '1yue'
        },
        {
          name: '2月',
          con: '2yue'
        },
        {
          name: '3月',
          con: '3yue'
        },
        {
          name: '4月',
          con: '4yue'
        },
        {
          name: '5月',
          con: '5yue'
        },
        {
          name: '6月',
          con: '6yue'
        },
        {
          name: '7月',
          con: '7yue'
        },
        {
          name: '8月',
          con: '8yue'
        },
        {
          name: '9月',
          con: '9yue'
        },
        {
          name: '10月',
          con: '10yue'
        },
        {
          name: '11月',
          con: '11yue'
        },
        {
          name: '12月',
          con: '12yue'
        }
      ],
      biaotou2: [
        {
          name: '日期（冲刺法）',
          con: 'dept_name'
        },
        {
          name: '全年',
          con: 'quannian'
        },
        {
          name: '1月',
          con: '1yue'
        },
        {
          name: '2月',
          con: '2yue'
        },
        {
          name: '3月',
          con: '3yue'
        },
        {
          name: '4月',
          con: '4yue'
        },
        {
          name: '5月',
          con: '5yue'
        },
        {
          name: '6月',
          con: '6yue'
        },
        {
          name: '7月',
          con: '7yue'
        },
        {
          name: '8月',
          con: '8yue'
        },
        {
          name: '9月',
          con: '9yue'
        },
        {
          name: '10月',
          con: '10yue'
        },
        {
          name: '11月',
          con: '11yue'
        },
        {
          name: '12月',
          con: '12yue'
        }
      ],
      huizong_mingxi_list: ['汇总', '明细'],
      huizong_mingxi: 0,
      bumen_zong: '',
      chanpin_zong: '',
      renyuan_zong: '',
      bumen_zong2: '',
      chanpin_zong2: '',
      renyuan_zong2: '',
      chaxun_or_xiugai: '',
      shaixuan: '部门',
      shaixuan_list: ['部门', '产品', '人员'],
      chutu: false // 出图变亮
    }
  },
  created () {
    this.jichu()
  },
  mounted () {
    this.$refs.mubiao_tu6.biaoge_dakai()
  },
  components: { tuliu },
  watch: {
    // 监听调整里的值
    neirong_list: {
      handler (newValue, oldValue) {
        this.zidong_shuzhi()
        this.panduan()
      },
      deep: true
    }
  },
  methods: {
    //   基础信息
    jichu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '6'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10237) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.length != 0) {
            this.year_list = []
            for (let i = 0; i < date.length; i++) {
              this.year_list.push(date[i] + '年')
            }
            this.nianfen_xianshi_yincang = false
            this.chaxun_shifou_chutu()
            date.forEach(it => {
              if (it == new Date().getFullYear()) {
                this.time = new Date().getFullYear() + '年'
                this.xuanze_nianfen_biao()
              }
            })
          } else {
            this.nianfen_xianshi_yincang = true
          }
        } else if (res.data.code == 10238) {}
      })
    },
    chaxun_jiekou () {
      let a = ''
      if (this.shaixuan == '部门') {
        a = '1'
      } else if (this.shaixuan == '产品') {
        a = '2'
      } else if (this.shaixuan == '人员') {
        a = '3'
      }
      query_as_aim_plan({
        data: {
          ent_id: this.$ent_id(),
          year: this.time.slice(0, 4),
          query_type: a
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data_list)
          const data = JSON.parse(res.data.body.plan)
          console.log(date)
          console.log(data)
          for (let i = 0; i < data.length; i++) {
            this.neirong_list[0][i + 1 + 'yue'] = data[i].rate
          }
          if (this.huizong_mingxi == 0) {
            this.neirong_list[1].dept_name = '计划' + this.shaixuan + '目标销售额'
            this.neirong_list.splice(2)
          } else {
            this.neirong_list.splice(2)
            this.neirong_list[1].dept_name = '合计'
            // this.neirong_list[1].quannian=''
            // for(let i=2;i<this.biaotou.length;i++){
            //     this.neirong_list[1][i-1+'yue']=''
            // }
            console.log(this.neirong_list)
            let name = ''
            if (this.shaixuan == '部门') {
              name = 'dept_name'
            } else if (this.shaixuan == '产品') {
              name = 'prod_name'
            } else if (this.shaixuan == '人员') {
              name = 'staff_name'
            }
            if (name != '') {
              for (let i = 0; i < date.length; i++) {
                this.neirong_list.push({
                  quannian: this.$qianwei(Number(date[i].amt_aim).toFixed(2)),
                  dept_name: date[i][name]
                })
              }
              for (let i = 2; i < this.neirong_list.length; i++) {
                for (let a = 2; a < this.biaotou.length; a++) {
                  // 有问题
                  // console.log(date[i-2])
                  this.neirong_list[i][this.biaotou[a].con] = this.$qianwei(Number(date[i - 2].map[this.biaotou[a].con.slice(0, -3)]).toFixed(2))
                  // this.$qianwei(Number(date[i-2].map[this.biaotou[a].con.slice(0, -3)]).toFixed(2))
                }
              }
            }
          }
          this.chaxun_or_xiugai = false
        } else if (res.data.code == 500) {}
      })
      query_as_aim_plan_spurt({
        data: {
          ent_id: this.$ent_id(),
          year: this.time.slice(0, 4),
          query_type: a
        }
      }).then(res => {
        this.neirong_list2 = []
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data_list)
          const data = JSON.parse(res.data.body.plan)
          console.log(date)
          console.log(data)
          let name = ''
          if (this.shaixuan == '部门') {
            name = 'dept_name'
          } else if (this.shaixuan == '产品') {
            name = 'prod_name'
          } else if (this.shaixuan == '人员') {
            name = 'staff_name'
          }
          let num = 0
          let num1 = 0
          let num2 = 0
          let num3 = 0
          let num4 = 0
          let num5 = 0
          let num6 = 0
          let num7 = 0
          let num8 = 0
          let num9 = 0
          let num10 = 0
          let num11 = 0
          for(let i = 0; i < date.length; i++) {
            num += date[i].map2[1],
            num1 += date[i].map2[2],
            num2 += date[i].map2[3],
            num3 += date[i].map2[4],
            num4 += date[i].map2[5],
            num5 += date[i].map2[6],
            num6 += date[i].map2[7],
            num7 += date[i].map2[8],
            num8 += date[i].map2[9],
            num9 += date[i].map2[10],
            num10 += date[i].map2[11],
            num11 += date[i].map2[12]
          }
          //console.log(numm11)
          console.log(this.neirong_list2)
          if(this.shaixuan == '部门') {
            this.neirong_list2.push({
            dept_name: '冲刺进度比例',
              quannian: ((num + num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9 + num10 + num11) / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
              ['1yue']: (num / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
              ['2yue']: (num1 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
              ['3yue']: (num2 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
              ['4yue']: (num3 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
              ['5yue']: (num4 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
              ['6yue']: (num5 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
              ['7yue']: (num6 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
              ['8yue']: (num7 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
              ['9yue']: (num8 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
              ['10yue']: (num9 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
              ['11yue']: (num10 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
              ['12yue']: (num11 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%'
            })
            this.neirong_list2.push({
              dept_name: '冲刺' + this.shaixuan + '销售目标',
              quannian: (num + num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9 + num10 + num11).toFixed(2),
              ['1yue']: num.toFixed(2),
              ['2yue']: num1.toFixed(2),
              ['3yue']: num2.toFixed(2),
              ['4yue']: num3.toFixed(2),
              ['5yue']: num4.toFixed(2),
              ['6yue']: num5.toFixed(2),
              ['7yue']: num6.toFixed(2),
              ['8yue']: num7.toFixed(2),
              ['9yue']: num8.toFixed(2),
              ['10yue']: num9.toFixed(2),
              ['11yue']: num10.toFixed(2),
              ['12yue']: num11.toFixed(2),
            })
          } else if(this.shaixuan == '产品') {
            this.neirong_list2.push({
              dept_name: '冲刺进度比例',
              quannian: ((num + num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9 + num10 + num11) / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
              ['1yue']: (num / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
              ['2yue']: (num1 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
              ['3yue']: (num2 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
              ['4yue']: (num3 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
              ['5yue']: (num4 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
              ['6yue']: (num5 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
              ['7yue']: (num6 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
              ['8yue']: (num7 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
              ['9yue']: (num8 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
              ['10yue']: (num9 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
              ['11yue']: (num10 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
              ['12yue']: (num11 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%'
            })
            this.neirong_list2.push({
              dept_name: '冲刺' + this.shaixuan + '销售目标',
              quannian: (num + num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9 + num10 + num11).toFixed(2),
              ['1yue']: num.toFixed(2),
              ['2yue']: num1.toFixed(2),
              ['3yue']: num2.toFixed(2),
              ['4yue']: num3.toFixed(2),
              ['5yue']: num4.toFixed(2),
              ['6yue']: num5.toFixed(2),
              ['7yue']: num6.toFixed(2),
              ['8yue']: num7.toFixed(2),
              ['9yue']: num8.toFixed(2),
              ['10yue']: num9.toFixed(2),
              ['11yue']: num10.toFixed(2),
              ['12yue']: num11.toFixed(2),
            })
          } else {
            this.neirong_list2.push({
              dept_name: '冲刺进度比例',
              quannian: ((num + num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9 + num10 + num11) / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
              ['1yue']: (num / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
              ['2yue']: (num1 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
              ['3yue']: (num2 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
              ['4yue']: (num3 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
              ['5yue']: (num4 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
              ['6yue']: (num5 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
              ['7yue']: (num6 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
              ['8yue']: (num7 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
              ['9yue']: (num8 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
              ['10yue']: (num9 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
              ['11yue']: (num10 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
              ['12yue']: (num11 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%'
            })
            this.neirong_list2.push({
              dept_name: '冲刺' + this.shaixuan + '销售目标',
              quannian: (num + num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9 + num10 + num11).toFixed(2),
              ['1yue']: num.toFixed(2),
              ['2yue']: num1.toFixed(2),
              ['3yue']: num2.toFixed(2),
              ['4yue']: num3.toFixed(2),
              ['5yue']: num4.toFixed(2),
              ['6yue']: num5.toFixed(2),
              ['7yue']: num6.toFixed(2),
              ['8yue']: num7.toFixed(2),
              ['9yue']: num8.toFixed(2),
              ['10yue']: num9.toFixed(2),
              ['11yue']: num10.toFixed(2),
              ['12yue']: num11.toFixed(2),
            })
          }
          console.log(this.neirong_list2)
            if(this.lo === 1) {
              this.neirong_list2 = []
            if(this.shaixuan == '部门'){
              this.neirong_list2.push({
                dept_name: '冲刺进度比例',
                quannian: ((num + num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9 + num10 + num11) / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
                ['1yue']: (num / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
                ['2yue']: (num1 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
                ['3yue']: (num2 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
                ['4yue']: (num3 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
                ['5yue']: (num4 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
                ['6yue']: (num5 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
                ['7yue']: (num6 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
                ['8yue']: (num7 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
                ['9yue']: (num8 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
                ['10yue']: (num9 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
                ['11yue']: (num10 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%',
                ['12yue']: (num11 / res.data.body.amt_aim_dept * 100).toFixed(2) + '%'
              })
              this.neirong_list2.push({
                dept_name: '合计',
                quannian: (num + num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9 + num10 + num11).toFixed(2),
                ['1yue']: Number(num).toFixed(2),
                ['2yue']: Number(num1).toFixed(2),
                ['3yue']: Number(num2).toFixed(2),
                ['4yue']: Number(num3).toFixed(2),
                ['5yue']: Number(num4).toFixed(2),
                ['6yue']: Number(num5).toFixed(2),
                ['7yue']: Number(num6).toFixed(2),
                ['8yue']: Number(num7).toFixed(2),
                ['9yue']: Number(num8).toFixed(2),
                ['10yue']: Number(num9).toFixed(2),
                ['11yue']: Number(num10).toFixed(2),
                ['12yue']: Number(num11).toFixed(2)
              })
              date.forEach(item => {
                this.neirong_list2.push({
                  dept_name: item.dept_name,
                  quannian: Number(item.map2[1] + item.map2[2] + item.map2[3] + item.map2[4] + item.map2[5] + item.map2[6] + item.map2[7] + item.map2[8] + item.map2[9] + item.map2[10] + item.map2[11] + item.map2[12]).toFixed(2),
                  ['1yue']: Number(item.map2[1]).toFixed(2),
                  ['2yue']: Number(item.map2[2]).toFixed(2),
                  ['3yue']: Number(item.map2[3]).toFixed(2),
                  ['4yue']: Number(item.map2[4]).toFixed(2),
                  ['5yue']: Number(item.map2[5]).toFixed(2),
                  ['6yue']: Number(item.map2[6]).toFixed(2),
                  ['7yue']: Number(item.map2[7]).toFixed(2),
                  ['8yue']: Number(item.map2[8]).toFixed(2),
                  ['9yue']: Number(item.map2[9]).toFixed(2),
                  ['10yue']: Number(item.map2[10]).toFixed(2),
                  ['11yue']: Number(item.map2[11]).toFixed(2),
                  ['12yue']: Number(item.map2[12]).toFixed(2)
                })
              })
            } else if(this.shaixuan == '产品'){
              this.neirong_list2.push({
                dept_name: '冲刺进度比例',
                quannian: ((num + num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9 + num10 + num11) / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
                ['1yue']: (num / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
                ['2yue']: (num1 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
                ['3yue']: (num2 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
                ['4yue']: (num3 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
                ['5yue']: (num4 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
                ['6yue']: (num5 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
                ['7yue']: (num6 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
                ['8yue']: (num7 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
                ['9yue']: (num8 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
                ['10yue']: (num9 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
                ['11yue']: (num10 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%',
                ['12yue']: (num11 / res.data.body.amt_aim_prod * 100).toFixed(2) + '%'
              })
              this.neirong_list2.push({
                dept_name: '合计',
                quannian: (num + num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9 + num10 + num11).toFixed(2),
                ['1yue']: Number(num).toFixed(2),
                ['2yue']: Number(num1).toFixed(2),
                ['3yue']: Number(num2).toFixed(2),
                ['4yue']: Number(num3).toFixed(2),
                ['5yue']: Number(num4).toFixed(2),
                ['6yue']: Number(num5).toFixed(2),
                ['7yue']: Number(num6).toFixed(2),
                ['8yue']: Number(num7).toFixed(2),
                ['9yue']: Number(num8).toFixed(2),
                ['10yue']: Number(num9).toFixed(2),
                ['11yue']: Number(num10).toFixed(2),
                ['12yue']: Number(num11).toFixed(2)
              })
              date.forEach(item => {
                this.neirong_list2.push({
                  dept_name: item.prod_name,
                  quannian: Number(item.map2[1] + item.map2[2] + item.map2[3] + item.map2[4] + item.map2[5] + item.map2[6] + item.map2[7] + item.map2[8] + item.map2[9] + item.map2[10] + item.map2[11] + item.map2[12]).toFixed(2),
                  ['1yue']: Number(item.map2[1]).toFixed(2),
                  ['2yue']: Number(item.map2[2]).toFixed(2),
                  ['3yue']: Number(item.map2[3]).toFixed(2),
                  ['4yue']: Number(item.map2[4]).toFixed(2),
                  ['5yue']: Number(item.map2[5]).toFixed(2),
                  ['6yue']: Number(item.map2[6]).toFixed(2),
                  ['7yue']: Number(item.map2[7]).toFixed(2),
                  ['8yue']: Number(item.map2[8]).toFixed(2),
                  ['9yue']: Number(item.map2[9]).toFixed(2),
                  ['10yue']: Number(item.map2[10]).toFixed(2),
                  ['11yue']: Number(item.map2[11]).toFixed(2),
                  ['12yue']: Number(item.map2[12]).toFixed(2),
                })
              })
            } else {
              this.neirong_list2.push({
                dept_name: '冲刺进度比例',
                quannian: ((num + num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9 + num10 + num11) / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
                ['1yue']: (num / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
                ['2yue']: (num1 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
                ['3yue']: (num2 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
                ['4yue']: (num3 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
                ['5yue']: (num4 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
                ['6yue']: (num5 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
                ['7yue']: (num6 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
                ['8yue']: (num7 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
                ['9yue']: (num8 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
                ['10yue']: (num9 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
                ['11yue']: (num10 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%',
                ['12yue']: (num11 / res.data.body.amt_aim_staff * 100).toFixed(2) + '%'
              })
              this.neirong_list2.push({
                dept_name: '合计',
                quannian: (num + num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9 + num10 + num11).toFixed(2),
                ['1yue']: Number(num).toFixed(2),
                ['2yue']: Number(num1).toFixed(2),
                ['3yue']: Number(num2).toFixed(2),
                ['4yue']: Number(num3).toFixed(2),
                ['5yue']: Number(num4).toFixed(2),
                ['6yue']: Number(num5).toFixed(2),
                ['7yue']: Number(num6).toFixed(2),
                ['8yue']: Number(num7).toFixed(2),
                ['9yue']: Number(num8).toFixed(2),
                ['10yue']: Number(num9).toFixed(2),
                ['11yue']: Number(num10).toFixed(2),
                ['12yue']: Number(num11).toFixed(2)
              })
              date.forEach(item => {
                this.neirong_list2.push({
                  dept_name: item.staff_name,
                  quannian: Number(item.map2[1] + item.map2[2] + item.map2[3] + item.map2[4] + item.map2[5] + item.map2[6] + item.map2[7] + item.map2[8] + item.map2[9] + item.map2[10] + item.map2[11] + item.map2[12]).toFixed(2),
                  ['1yue']: Number(item.map2[1]).toFixed(2),
                  ['2yue']: Number(item.map2[2]).toFixed(2),
                  ['3yue']: Number(item.map2[3]).toFixed(2),
                  ['4yue']: Number(item.map2[4]).toFixed(2),
                  ['5yue']: Number(item.map2[5]).toFixed(2),
                  ['6yue']: Number(item.map2[6]).toFixed(2),
                  ['7yue']: Number(item.map2[7]).toFixed(2),
                  ['8yue']: Number(item.map2[8]).toFixed(2),
                  ['9yue']: Number(item.map2[9]).toFixed(2),
                  ['10yue']: Number(item.map2[10]).toFixed(2),
                  ['11yue']: Number(item.map2[11]).toFixed(2),
                  ['12yue']: Number(item.map2[12]).toFixed(2),
                })
              })
            }
          }
        } else if (res.data.code == 500) {}
      })
      this.chaxun_shifou_chutu()
    },
    // 判断是否可以点保存
    panduan () {
      if (this.year_list.length != 0) {
        const a = []
        for (let i = 0; i < this.year_list.length; i++) {
          if (this.time != this.year_list[i]) {
            a.push(1)
          }
        }
        if (a.length == this.year_list.length) {
          this.panduan_shifou()
        } else {
          this.shifou_baocun = false
          console.log(222)
        }
      } else {
        this.panduan_shifou()
      }
    },
    panduan_shifou () {
      const a = []
      for (let i = 2; i < this.biaotou.length; i++) {
        if (this.neirong_list[0][this.biaotou[i].con] != '') {
          this.shifou_baocun = true
        } else {
          this.shifou_baocun = false
        }
      }
    },
    // 选择年份确定
    xuanze_nianfen_queding () {
      if (this.nian_sj != '') {
        let yiyou_nianfen = false
        if (this.year_list.length != 0) {
          this.year_list.forEach(item => {
            if (this.nian_sj.getFullYear() + '年' == item) {
              yiyou_nianfen = true
              this.nian_sj = ''
            }
          })
        }
        if (!yiyou_nianfen) {
          supply_as_aim_plan_data({
            data: {
              ent_id: this.$ent_id(),
              year: this.nian_sj.getFullYear() + ''
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              const you_meiyou = []
              if (this.nian_sj.length != 0) {
                for (let i = 0; i < this.year_list.length; i++) {
                  if (this.nian_sj.getFullYear() + '年' != this.year_list[i]) {
                    you_meiyou.push(1)
                  }
                }
              }
              setTimeout(() => {
                console.log(this.nian_sj)
                if (this.nian_sj.length != 0) {
                  if (you_meiyou.length != this.year_list.length) {
                    this.$message({
                      message: '您不能选择当前已有的年份',
                      type: 'warning'
                    })
                  } else {
                    if (date.amt_aim_dept == undefined) {
                      this.$message({
                        message: '没有该年份的销售目标进度',
                        type: 'warning'
                      })
                    } else {
                      const list = [
                        {
                          dept_name: '计划进度比例',
                          quannian: 0,
                          '1yue': '',
                          '2yue': '',
                          '3yue': '',
                          '4yue': '',
                          '5yue': '',
                          '6yue': '',
                          '7yue': '',
                          '8yue': '',
                          '9yue': '',
                          '10yue': '',
                          '11yue': '',
                          '12yue': ''
                        },
                        {
                          dept_name: '计划部门目标销售额',
                          quannian: '',
                          '1yue': '',
                          '2yue': '',
                          '3yue': '',
                          '4yue': '',
                          '5yue': '',
                          '6yue': '',
                          '7yue': '',
                          '8yue': '',
                          '9yue': '',
                          '10yue': '',
                          '11yue': '',
                          '12yue': ''
                        }
                      ]
                      const list2 = [
                        {
                          dept_name: '实际/计划进度比例',
                          quannian: 0,
                          '1yue': '',
                          '2yue': '',
                          '3yue': '',
                          '4yue': '',
                          '5yue': '',
                          '6yue': '',
                          '7yue': '',
                          '8yue': '',
                          '9yue': '',
                          '10yue': '',
                          '11yue': '',
                          '12yue': ''
                        },
                        {
                          dept_name: '实际/计划部门目标销售额',
                          quannian: '',
                          '1yue': '',
                          '2yue': '',
                          '3yue': '',
                          '4yue': '',
                          '5yue': '',
                          '6yue': '',
                          '7yue': '',
                          '8yue': '',
                          '9yue': '',
                          '10yue': '',
                          '11yue': '',
                          '12yue': ''
                        }
                      ]
                      this.neirong_list = list
                      this.neirong_list2 = list2
                      this.nianfen_xianshi_yincang = false
                      this.bumen_zong = date.amt_aim_dept
                      this.chanpin_zong = date.amt_aim_prod
                      this.renyuan_zong = date.amt_aim_staff
                      this.bumen_zong2 = date.amt_aim_dept
                      this.chanpin_zong2 = date.amt_aim_prod
                      this.renyuan_zong2 = date.amt_aim_staff
                      this.chaxun_or_xiugai = true
                      this.time = this.nian_sj.getFullYear() + '年'
                    }
                  }
                }
              }, 0)
            } else if (res.data.code == 500) {}
          })
        } else {
          this.$message({
            message: '已有该年份数据',
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: '请选择年份',
          type: 'warning'
        })
      }
    },
    shaixuan_biao () {
      console.log(this.shaixuan)
      this.lo = 0
      if (this.huizong_mingxi == 0) {
        this.neirong_list[1].dept_name = '计划' + this.shaixuan + '目标销售额'
        this.neirong_list2[1].dept_name = '实际/计划' + this.shaixuan + '目标销售额'
      } else {
        // this.chaxun_jiekou()
      }
      this.huizong_mingxi = 0
      this.chaxun_jiekou()
    },
    // 自动值
    zidong_shuzhi () {
      if (this.neirong_list[1].dept_name == '计划部门目标销售额') {
        this.neirong_list[1].quannian = this.$qianwei(Number(this.bumen_zong).toFixed(2))
        this.zidong_suan()
      }
      if (this.neirong_list[1].dept_name == '计划产品目标销售额') {
        this.neirong_list[1].quannian = this.$qianwei(Number(this.chanpin_zong).toFixed(2))
        this.zidong_suan()
      }
      if (this.neirong_list[1].dept_name == '计划人员目标销售额') {
        this.neirong_list[1].quannian = this.$qianwei(Number(this.renyuan_zong).toFixed(2))
        this.zidong_suan()
      }
      if (this.neirong_list2[1].dept_name == '实际/计划部门目标销售额' || this.shaixuan == '部门') {
        this.neirong_list2[1].quannian = this.$qianwei(Number(this.bumen_zong2).toFixed(2))
        console.log(this.bumen_zong2)
        this.zidong_suan()
      }
      if (this.neirong_list2[1].dept_name == '实际/计划产品目标销售额' || this.shaixuan == '产品') {
        this.neirong_list2[1].quannian = this.$qianwei(Number(this.chanpin_zong2).toFixed(2))
        this.zidong_suan()
      }
      if (this.neirong_list2[1].dept_name == '实际/计划人员目标销售额' || this.shaixuan == '人员') {
        this.neirong_list2[1].quannian = this.$qianwei(Number(this.renyuan_zong2).toFixed(2))
        this.zidong_suan()
      }
      // 计划进度比例
      for (let i = 0; i < this.neirong_list.length; i++) {
        if (this.neirong_list[i].dept_name == '计划进度比例') {
          let zhi = ''
          for (let a = 1; a <= 12; a++) {
            if (this.neirong_list[i][a + 'yue'].length != 0) {
              zhi = Number(zhi) + Number(this.neirong_list[i][a + 'yue'])
            }
          }
          this.neirong_list[i].quannian = Number(zhi) + ''
        }
      }
      for (let i = 0; i < this.neirong_list2.length; i++) {
        if (this.neirong_list2[i].dept_name == '实际/计划进度比例') {
          let zhi = ''
          for (let a = 1; a <= 12; a++) {
            if (this.neirong_list[i][a + 'yue'].length != 0) {
              zhi = Number(zhi) + Number(this.neirong_list[i][a + 'yue'])
              this.neirong_list2[i][a + 'yue'] = Number(this.neirong_list[i][a + 'yue'])
            }
          }
          this.neirong_list2[i].quannian = Number(zhi) + ''
        }
      }
      // if(this.neirong_list[1].dept_name=='合计'){
      //     let a=''
      //     for(let i=2;i<this.neirong_list.length;i++){
      //         a=this.$qianwei(Number(this.$shuzi(this.neirong_list[i].quannian)+Number(a)).toFixed(2))
      //     }
      //     if(a!=''){
      //         this.neirong_list[1].quannian=a
      //         this.zidong_suan()
      //     }
      // }
    },
    zidong_shuzhi2 () {
      if (this.neirong_list2[1].dept_name == '实际/计划部门目标销售额' || this.shaixuan == '部门') {
        this.neirong_list2[1].quannian = this.$qianwei(Number(this.bumen_zong2).toFixed(2))
        console.log(this.bumen_zong2)
        this.zidong_suan2()
      }
      if (this.neirong_list2[1].dept_name == '实际/计划产品目标销售额' || this.shaixuan == '产品') {
        this.neirong_list2[1].quannian = this.$qianwei(Number(this.chanpin_zong2).toFixed(2))
        this.zidong_suan2()
      }
      if (this.neirong_list2[1].dept_name == '实际/计划人员目标销售额' || this.shaixuan == '人员') {
        this.neirong_list2[1].quannian = this.$qianwei(Number(this.renyuan_zong2).toFixed(2))
        this.zidong_suan2()
      }
      for (let i = 0; i < this.neirong_list2.length; i++) {
        if (this.neirong_list2[i].dept_name == '实际/计划进度比例') {
          let zhi = ''
          for (let a = 1; a <= 12; a++) {
            if (this.neirong_list2[i][a + 'yue'].length != 0) {
              zhi = Number(zhi) + Number(this.neirong_list2[i][a + 'yue'])
              this.neirong_list2[i][a + 'yue'] = Number(this.neirong_list2[i][a + 'yue'])
            }
          }
          this.neirong_list2[i].quannian = Number(zhi).toFixed(2) + ''
        }
      }
      // if(this.neirong_list[1].dept_name=='合计'){
      //     let a=''
      //     for(let i=2;i<this.neirong_list.length;i++){
      //         a=this.$qianwei(Number(this.$shuzi(this.neirong_list[i].quannian)+Number(a)).toFixed(2))
      //     }
      //     if(a!=''){
      //         this.neirong_list[1].quannian=a
      //         this.zidong_suan()
      //     }
      // }
    },
    //  、、2020/6/12修改比例比例值取值有问题
    zidong_suan2 () {
      for (let i = 0; i < this.neirong_list2.length; i++) {
        if (this.neirong_list2[i].dept_name == '实际/计划进度比例') {
          let zhi = ''
          for (let a = 1; a <= 12; a++) {
            if (this.neirong_list[i][a + 'yue'].length != 0) {
              zhi = Number(zhi) + Number(this.neirong_list[i][a + 'yue'])
              this.neirong_list2[i][a + 'yue'] = Number(this.neirong_list[i][a + 'yue'])
            }
          }
          this.neirong_list2[i].quannian = Number(zhi) + ''
        }
      }
    },
    zidong_suan () {
      for (let i = 2; i < this.biaotou.length; i++) {
        // console.log(i)
        // console.log(this.biaotou[i].con)
        if (this.neirong_list[0][this.biaotou[i].con] != '') {
          this.neirong_list[1][this.biaotou[i].con] = this.$qianwei(((Number(this.$shuzi(this.neirong_list[1].quannian)) * Number(this.neirong_list[0][this.biaotou[i].con])) / 100).toFixed(2))
        } else {
          this.neirong_list[1][this.biaotou[i].con] = 0
        }
      }
      for (let i = 2; i < this.biaotou2.length; i++) {
        console.log(i)
        console.log(this.biaotou2[i].con)
        console.log(this.neirong_list2[1].quannian)
        console.log(this.$qianwei(((Number(this.$shuzi(this.neirong_list2[1].quannian)) * Number(this.neirong_list2[0][this.biaotou2[i].con])) / 100).toFixed(2)))
        if (this.neirong_list2[0][this.biaotou2[i].con] != '') {
          this.neirong_list2[1][this.biaotou2[i].con] = this.$qianwei(((Number(this.$shuzi(this.neirong_list2[1].quannian)) * Number(this.neirong_list2[0][this.biaotou2[i].con])) / 100).toFixed(2))
        } else {
          this.neirong_list2[1][this.biaotou2[i].con] = 0
        }
      }
    },
    // 选择年份取消
    xuanze_nianfen_quxiao () {
      if (this.year_list.length == 0) {
        this.$router.push('/aspreackeven')
        sessionStorage.setItem('pc_mlbb_mubiao_biao', '-1')
      } else {
        this.nianfen_xianshi_yincang = false
      }
    },
    // 表头颜色
    headerCellStyle (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;width:2.5rem;'
    },
    cellStyle_tiao (row) {
      // console.log(row.column)
      if (row.row.dept_name == '合计' && row.column.property != 'dept_name') {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;width:2.5rem;background:#E2EFDA;'
      }
      if (row.column.label != '日期（计划法）' || row.column.label != '日期（冲刺法）') {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;width:2.5rem;'
      }
      if (row.row.dept_name == '合计') {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:center;width:2.5rem;background:#D8E6F7;'
      } else {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:center;width:2.5rem;'
      }
      // if(row.col.zhi!=undefined){

      // }
    },
    // 展示表格保存
    dianji_baocun () {
      for (let i = 0; i < this.neirong_list.length; i++) {
        if (this.neirong_list[i].dept_name == '计划进度比例') {
          if (this.neirong_list[i].quannian == 100) {
            const a = []
            for (let i = 2; i < this.biaotou.length; i++) {
              a.push({
                month: i - 1 + '',
                rate: this.neirong_list[0][this.biaotou[i].con]
              })
            }
            if (a.length != 0) {
              insert_as_aim_plan({
                data: {
                  user_id: this.$jichuxinxi().user_id,
                  ent_id: this.$ent_id(),
                  year: this.time.slice(0, 4),
                  list: a
                }
              }).then(res => {
                console.log(res)
                if (res.data.code == 200) {
                  this.$message({
                    message: '保存成功',
                    type: 'success'
                  })
                  this.shifou_baocun = false
                  this.chaxun_or_xiugai = false
                  this.chutu = true // 出图变亮
                } else if (res.data.code == 500) {}
              })
            }
          } else {
            this.$message({
              message: '进度比例不是100%',
              type: 'warning'
            })
          }
        }
      }
    },
    // 点击出图
    dianji_chutu () {
      if (this.chutu) {
        // this.chuxian='111'
        // this.quan=this.time
        // console.log(this.chuanshu)

        this.$refs.mubiao_tu6.dakai_quanping(
          {
            time: this.time,
            chutu: true,
            biaoge_nei: true
          }
        )
        // this.chutu=false
      } else {
        this.$message({
          message: '请先进行保存',
          type: 'warning'
        })
      }
    },
    // 查询是否可以点击出图
    chaxun_shifou_chutu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '6'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10237) {
          const data = JSON.parse(res.data.body.data)
          console.log(data)
          console.log(this.time)
          if (data.length != 0) {
            // if(data.map(item=>{if(this.time.slice(0,4)==item){return true}})){
            //     this.chutu=true
            // }

            for (let a = 0; a < data.length; a++) {
              if (data[a] == this.time.slice(0, 4)) {
                this.chutu = true
                break
              }
            }
          } else {
            this.chutu = false
          }
        }
      })
    },
    // 点击关闭
    dianji_guanbi () {
      this.$router.push('/aspreackeven')
    },
    // 选择年份
    xuanze_nianfen_biao () {
      supply_as_aim_plan_data({
        data: {
          ent_id: this.$ent_id(),
          year: this.time.slice(0, 4)
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          const list = [
            {
              dept_name: '计划进度比例',
              quannian: '100',
              '1yue': '',
              '2yue': '',
              '3yue': '',
              '4yue': '',
              '5yue': '',
              '6yue': '',
              '7yue': '',
              '8yue': '',
              '9yue': '',
              '10yue': '',
              '11yue': '',
              '12yue': ''
            },
            {
              dept_name: '计划部门目标销售额',
              quannian: '',
              '1yue': '',
              '2yue': '',
              '3yue': '',
              '4yue': '',
              '5yue': '',
              '6yue': '',
              '7yue': '',
              '8yue': '',
              '9yue': '',
              '10yue': '',
              '11yue': '',
              '12yue': ''
            }
          ]
          this.neirong_list = list
          this.nianfen_xianshi_yincang = false
          this.bumen_zong = date.amt_aim_dept
          this.chanpin_zong = date.amt_aim_prod
          this.renyuan_zong = date.amt_aim_staff
          this.chaxun_or_xiugai = true
          setTimeout(() => {
            this.chaxun_jiekou()
          }, 0)
        } else if (res.data.code == 500) {}
      })
    },
    // 切换是总还是明细
    qiehuan_zong_mingxi (index) {
      this.lo = index
      if (this.time != '') {
        if (this.chutu) {
          this.huizong_mingxi = index
          this.chaxun_jiekou()
        } else {
          this.$message({
            message: '请保存后切换',
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: '请选择年份',
          type: 'warning'
        })
      }
    },
    shuzhi_bilv (i, data) {
      if (i[data] <= 0) {
        i[data] = 0
      } else if (i[data] > 100) {
        i[data] = 100
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tablesix {
    min-height:7.2rem;
    background:#F4F7FD;
    padding: 0.31rem 0.31rem 0.34rem 0.31rem;
    // width:100%;
    .beijing{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.3);
        .box1{
            height:3.03rem;
            width:6.44rem;
            background:#fff;
            border-radius: 0.2rem;
            position: absolute;
            left:50%;
            margin-left: -3.22rem;
            top:50%;
            margin-top: -1.51rem;
            .tit_box{
                font-size: 0.18rem;
                color:#1A2533;
                text-align: center;
                padding-top:0.25rem;
                margin-bottom: 0.45rem;
            }
            .con{
                font-size: 0.14rem;
                color:#4C4A4D;
                text-align: center;
                margin-bottom: 0.2rem;
            }
            .con_box{
                display: flex;
                justify-content: center;
                font-size: 0.12rem;
                color:#4C4A4D;
                align-items: center;
            }
            .foot{
                display: flex;
                justify-content: flex-end;
                margin-right: 0.6rem;
                margin-top:0.64rem;
                span{
                    display: block;
                    height:0.43rem;
                    width:0.83rem;
                    line-height: 0.43rem;
                    border:0.01rem solid #979797;
                    text-align: center;
                    font-size: 0.14rem;
                    color:#888888;
                    border-radius: 0.02rem;
                    cursor: pointer;
                    &:nth-child(1){
                        background: #F16977;
                        border:0.01rem solid #F16977;
                        color:#fff;
                        margin-right:0.19rem;
                    }
                }
            }
        }
    }
    .box{
        min-height:7.2rem;
        width:17.2rem;
        position: relative;
        .box_tit{
            font-size: 0.22rem;
            color:#1A2533;
            text-align: center;
            margin-bottom: 0.33rem;
        }
        .lishi{
            display: flex;
            align-items: center;
            position: absolute;
            right:0;
            top:0.04rem;
            font-size: 0.14rem;
            color:#4C4A4D;
            cursor: pointer;
            i{
                display: block;
                height:0.25rem;
                width:0.28rem;
                background: url('../../assets/rzhi_no.png') no-repeat;
                background-position:center center;
                margin-left: 0.1rem;
            }
            &:hover i{
                background: url('../../assets/rzhi_ok.png') no-repeat;
                background-position:center center;
            }
        }
        .table_tit{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.54rem;
            .table_tit_left{
                display: flex;
                align-items: center;
                .xinjian{
                    font-size: 0.14rem;
                    background:#9a86db;
                    padding: 0.03rem 0.16rem;
                    border:0.01rem solid #9a86db;
                    border-radius: 0.02rem;
                    color:#fff;
                    margin-right: 0.15rem;
                    cursor: pointer;
                }
                .table_tit_left_nianfen{
                    font-size: 0.14rem;
                    color:#4c4a4d;
                }
            }
            .xuanze{
                display: flex;
                align-items: center;
                margin-left: -1.66rem;
                p{
                    display: flex;
                    align-items: center;
                    img{
                        height:0.17rem;
                        width:0.17rem;
                        margin-right: 0.08rem;
                    }
                    margin-right: 0.29rem;
                    cursor: pointer;
                }
            }
            .table_tit_right{
                font-size: 0.1rem;
                color:#444;
            }
        }
        .table{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height:0.69rem;
            padding: 0 0.21rem;
            border:0.01rem solid #888;
            margin-top:0.2rem;
            div{
                display: flex;
                align-items: center;
                p{
                    padding: 0.03rem 0.16rem;
                    color:#838383;
                    font-size: 0.14rem;
                    border-radius: 0.02rem;
                    border:0.01rem solid #979797;
                    margin-right:0.12rem;
                    cursor: pointer;
                }
            }
            .table_right{
                p{
                    &:nth-child(2){
                        background:#9a86db;
                        color:#fff;
                        border:0.01rem solid #9a86db;
                    }
                    &:nth-child(3){
                        background:#B8C1CE;
                        color:#fff;
                        border:0.01rem solid #B8C1CE;
                    }
                }
            }
        }
        .table_con{
        }
    }
    @media screen and (max-width: 1600px) {
        .box {
            width:100%;
        }
    }
}
</style>
